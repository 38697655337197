<template>
  <div class="container">
    <!-- 添加按钮 -->
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          size="medium"
          icon="el-icon-plus"
          style="margin-bottom: 10px"
          @click="AddClick">添加
      </el-button>
<!--      <el-button type="info" size="medium" icon="el-icon-edit" @click="homeManagerShow=true">首页案例管理</el-button>-->
      <span style="margin-left: 30px">分类：</span>
      <el-select placeholder="请选择案例类型" clearable style="width: 150px" v-model="listQuery.type" @change="getList">
        <el-option label="案例" value="case"></el-option>
        <el-option label="新闻" value="news"></el-option>
        <el-option label="产品" value="product"></el-option>
      </el-select>
<!--      <el-input style="width: 15%;margin-left: 10px;"></el-input>-->
<!--      <el-button type="primary" icon="el-icon-search" size="medium" style="margin-left:10px">搜索</el-button>-->
    </div>

    <el-card class="box-card" style="margin-top: 10px;" v-loading="loading">
      <div v-for="data in datalist" :key="data" class="text item">
        <div style="display: inline-block;vertical-align: top">
<!--          <a href="http://localhost:8080" target="_blank">-->
            <img class="blog_img" :src="data.coverImage">
<!--          </a>-->
        </div>
        <div style="display: inline-block;vertical-align: top;margin-left:15px;width: 65%;position: relative">
<!--          <a class="blog_title_a" href="http://localhost:8080" target="_blank">-->
            <p class="blog_title">{{data.title}}</p>
<!--          </a>-->
<!--          <a class="blog_text_a" href="http://localhost:8080" target="_blank">-->
            <p class="blog_text">{{data.simpleDesc}}</p>
<!--          </a>-->
            <div class="blog_bottom"><span>发布日期:</span><span class="blog_date">{{ new Date(data.created_at).toLocaleDateString() }}</span><span>浏览量:</span><span>{{ data.readings }}</span>
          </div>
        </div>
        <div style="display: inline-block;margin-top: 45px;width: 20%;text-align: center">
          <el-button-group>
            <el-button type="primary" icon="el-icon-edit" size="small" @click="editItem(data)">编辑</el-button>
            <el-button type="info" icon="el-icon-view" size="small" @click="visitItem(data)">浏览</el-button>
            <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                :icon="InfoFilled"
                icon-color="#626AEF"
                title="确认删除吗？"
                @confirm="deleteItem(data.strID)">
              <template #reference>
                <el-button type="danger" icon="el-icon-delete" size="small" >删除</el-button>
              </template>
            </el-popconfirm>
          </el-button-group>
        </div>
        <el-divider></el-divider>
      </div>
      <div style="margin-top:20px;text-align:right;">
        <el-pagination
            background
            :total="total"
            :current-page="listQuery.pageNum"
            :page-size="listQuery.pageCount"
            :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
            layout="prev,pager,next,jumper,sizes,total"
            @size-change="pageSizeChange"
            @current-change="currPageChange"
        />
      </div>
    </el-card>

    <!--    点击添加按钮-->
    <el-dialog
        v-model="toShow"
        :title="textMap[dialogStatus]"
        destroy-on-close
        :close-on-click-modal="closeOnClick" :close-on-press-escape="closeOnClick"
        width="75%">
      <el-form ref="form" label-width="80px" :rules="rules" :model="formModel">
        <el-form-item label="标题" prop="title">
          <el-input style="width: 500px" placeholder="请输入标题" v-model="formModel.title"></el-input>
        </el-form-item>
        <el-form-item label="副标题">
          <el-input style="width: 500px" placeholder="请输入副标题，选填" v-model="formModel.subTitle"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input
              type="textarea"
              :autosize="{minRows: 3}"
              placeholder="请输入简介，选填"
              style="width: 800px"
              v-model="formModel.simpleDesc">
          </el-input>
<!--          <el-date-picker type="date" placeholder="可不选，默认为当前日期"></el-date-picker>-->
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select placeholder="请选择类型" clearable style="width: 150px" v-model="formModel.type" >
            <el-option label="新闻" value="news"></el-option>
            <el-option label="案例" value="case"></el-option>
            <el-option label="产品" value="product"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面图片" prop="img">
          <el-upload
              class="avatar-uploader"
              ref="picimg"
              :action="uploadUrl"
              :headers="picheaders"
              :data="uploadtype"
              :class="{disabled:uploadDisabled}"
              list-type="picture-card"
              :before-upload="beforeUpload"
              :on-preview="handlePictureCardPreview"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :file-list="fileList">
            <i class="el-icon-plus" />
<!--              accept=".jpg,.jpeg,.png"-->
          </el-upload>
        </el-form-item>
        内容:
        <wangEditor :value="formModel.content" :isClear="isClear" @change="wangEditorChange"></wangEditor>

      </el-form>
      <div style="text-align: right">
        <el-button @click="toShow = false">取 消</el-button>
        <el-button type="primary" @click="sure('form')">确 定</el-button>
      </div>
    </el-dialog>


    <!--    点击首页案例管理按钮-->
    <el-dialog
        title="首页案例管理"
        v-model="homeManagerShow"
        width="40%">
        <el-button
            class="filter-item"
            type="primary"
            size="small"
            icon="el-icon-plus">添加</el-button>
      <el-card class="homeManagerShowcard" shadow="hover">
        <p style="font-size: 17px">广州图书馆
          <el-button type="text" style="float: right;margin-top: -8px">删除</el-button>
        </p>
        <p style="font-size: 14px;text-indent: 2em;margin-top: 10px">到现在都没有消散，闹得沸沸扬扬。对于男方的出轨，张嘉倪一句爱得起放得下，再也没有下文。反倒将所有重心放在事业上，每次亮相都
          能惊艳大众，反而让粉丝们呼吁女神，抓紧离开渣男，带着孩子生活更好。对于大家的劝离，</p>
      </el-card>
    </el-dialog>

    <el-dialog
        title="查看"
        v-model="toVisit"
        width="80%">
      <div class="watch_news">
        <div class="watch_news_top">
          <p class="watch_news_p">{{visitform.title}}</p>
          <div class="watch_news_date"><span>发表日期：</span><span>{{visitform.time}}</span></div>
        </div>
        <div class="watch_news_content" v-html="visitform.content">

        </div>
      </div>

      <div style="text-align: right">
        <el-button type="primary" @click="toVisit = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import wangEditor from "@/components/wangEditor";
import global from "@/utils/global";
import md5 from "js-md5";
import Compressor from "compressorjs";
export default {
  name: "casesManager",
  components: { wangEditor },
  data() {
    var checkImg = (rule, value, callback) => {
      if (this.fileList.length === 0) {
        callback(new Error('请选择封面'));
      } else {
        callback();
      }
    };
    return {
      closeOnClick: false,
      dialogStatus: '',
      isClear: false,//设置为true的时候，这个可以用this.wangEditorDetail=''来替代
      // wangEditorDetail: "",
      toShow: false,
      homeManagerShow: false,

      loading: false,

      dialogImageUrl: '',
      toVisit: false,


      textMap: {
        update: '编辑',
        create: '添加'
      },

      picheaders: {},
      uploadUrl: '',
      fileList: [],

      datalist: [],

      total: 0,
      listQuery : {
        "pageNum": 1,
        "pageCount": 10,
        "type": 'case'
      },
      formModel:{
        author: '',
        content: '',
        coverImage: '',
        simpleDesc: '',
        subTitle: '',
        title: '',
        type: ''
      },
      uploadtype: {
        type: 'image'
      },
      visitform: {
        title: '',
        time: '',
      },
      rules: {
        title: [{
          required: true, message: '请输入标题', trigger: 'blur'
        }],
        type: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        img: [
          { validator: checkImg, trigger: 'blur' }
        ]
      },
      now: Date.parse(new Date()) / 1000,
    }
  },
  computed: {
    uploadDisabled: function() {
      if (this.fileList.length>0) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    // this.uploadUrl = this.localpath+this.localpathapi
    this.uploadUrl = global.syOssUrl + '/upload2Oss'
    this.getList()
  },
  mounted() {
    // this.wangEditorDetail = "我是默认值"; //设置富文本框默认显示内容
  },
  methods: {
    wangEditorChange(val) {
      this.formModel.content = val
    },
    getList(){
      this.loading = true
      this.$axios({
        url: this.localpath + 'officialweb/searchArticles',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.datalist = res.data.msg.articles
          this.total = res.data.msg.total
        }
        this.loading = false
      })

    },
    AddClick() {

      this.dialogStatus = 'create'
      this.toShow = true
      this.fileList = []

      this.formModel = {
        author: '',
        content: '',
        coverImage: '',
        simpleDesc: '',
        subTitle: '',
        title: '',
        type: ''
      }
    },
    sure(formName){
      if(this.dialogStatus === 'create') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // if(this.fileList.length === 0){
            //   this.$message({
            //     message: '请选择封面图片',
            //     type: 'error'
            //   })
            //   return
            // }
            this.$axios({
              url: this.localpath + 'officialweb/createArticle',
              method: 'post',
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })

              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.toShow = false
          }
        });
      }else{
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log('内容'+this.formModel.content)
            this.$axios({
              url: this.localpath + 'officialweb/editArticle',
              method: 'post',
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })

              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.toShow = false
          }
        });
      }
      // if(this.formModel.title === undefined  || this.formModel.title === ''){
      //   this.$message.error('请输入标题');
      // }
      // if(this.formModel.type === undefined  || this.formModel.type === ''){
      //   this.$message.error('请选择类型');
      // }
    },
    beforeUpload(file) {

      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg.toLowerCase() === 'jpg'
      const extension2 = testmsg.toLowerCase() === 'jepg'
      const extension3 = testmsg.toLowerCase() === 'png'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: '上传图片只能是jpg/jepg/png格式!',
          type: 'warning'
        });
        return false
      }
      return new Promise((resolve, reject) => {
        this.now = Date.parse(new Date()) / 1000;
        this.picheaders =  {
          'timestamp': this.now,
          'sign': md5('6486b66b41f8df68777eac23'+this.now)
        },
        new Compressor(file, {
          quality: 0.6, // 设置压缩质量，范围为 0 到 1，默认为 0.8
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },

    handlePictureCardPreview(file) {
      //默认是下载而不是打开
      window.open(file.url)
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },
    // 上传图片移除
    handleRemove(file, fileList) {
      this.fileList = fileList
      this.formModel.coverImage = ''
    },
    handleSuccess(response,file,fileList) {
      if(response.code === 0){
        this.fileList = fileList
        this.formModel.coverImage = response.msg
      }
    },

    pageSizeChange(pageCount){
      this.listQuery.pageNum = 1
      this.listQuery.pageCount = pageCount
      this.getList()
    },
    currPageChange(pageNum){
      if(pageNum!==null) {
        this.listQuery.pageNum = pageNum
        this.getList()
      }
    },
    editItem(data){
      console.log(this.formModel)

      this.formModel.simpleDesc = data.simpleDesc
      this.formModel.title = data.title
      this.formModel.subTitle = data.subTitle
      this.formModel.type = data.type
      this.formModel.strID = data.strID
      this.formModel.content =data.content
      this.fileList = []
      this.fileList.push({url:data.coverImage})
      this.formModel.coverImage = data.coverImage


      this.dialogStatus = 'update'

      this.toShow = true
    },
    visitItem(data){
      this.visitform.title = data.title
      this.visitform.time = new Date(data.created_at).toLocaleDateString()
      this.visitform.content = data.content
      this.toVisit = true

    },
    deleteItem(id){
      this.$axios({
        url: this.localpath + 'officialweb/delArticle',
        method: 'post',
        data:JSON.stringify({strid: id}),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        }else{
          this.$message({
            message:res.data.msg,
            type: 'error'
          })
        }
        this.getList()
      })
    }
    //转base64
    // convertImgToBase64(imageFile, callback, errorCallback) {
    //   try {
    //     let reader = new FileReader()
    //     reader.readAsDataURL(imageFile)
    //     reader.onload = function (e) {
    //       if (callback) {
    //         let base64Str = e.target.result
    //         callback(base64Str)
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error)
    //     if (errorCallback) {
    //       errorCallback(error)
    //     }
    //   }
    // }
  }
};
</script>
<style scoped>
:deep .el-upload--picture-card {
  width: 150px;
  height: 100px;
  line-height: 100px;
  position: relative;
}

:deep .el-upload-list--picture-card .el-upload-list__item {
  width: 150px;
  height: 100px;
}
.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
.blog_img {
  height: 110px;
  width: 150px;
  vertical-align: top;
}

.blog_title_a {
  color: black;
  font-size: 20px;
  line-height: 30px;
}

.blog_title_a:hover {
  text-decoration: underline;
}

.blog_text {
  margin: 3px 0 10px;
  line-height: 20px;
  color: #999999;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog_text:hover {
  color: #6b6b6b
}

.blog_title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.blog_bottom {
  position: absolute;
  top: 95px;
  color: #999999;
  font-size: 14px;
}

.blog_date {
  margin-right: 30px;
}

.disabled /deep/ .el-upload--picture-card {
  display: none;
}

/* 图片上传 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.el-upload--text {
  width: 165px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.homeManagerShowcard{
  margin-top: 15px;
}

.watch_news {
  width: 1200px;
  margin: 40px auto;
}
.watch_news_top{
  text-align: center;
  border-bottom: solid 1px #eee;
  padding-bottom: 30px;
}
.watch_news_p {
  line-height: 32px;
  font-size: 24px;
  color: #333;
}
.watch_news_date{
  line-height: 20px;
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}
.watch_news_content{
  padding: 40px 30px;
  box-sizing: border-box;
  border-bottom: solid 1px #eee;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  text-align: justify;
}
</style>

